<script>
import Loading from '@/components/general/Loading'
export default {
  name: 'Categories',
  components: {
    Loading
  },
  data () {
    return {
      ready: false,
      categories: [],
      formData: {
        selectedCategories: []
      }
    }
  },
  computed: {
    isInvite () {
      return this.$route.query.isInvite === 'true' || this.$route.query.isInvite === true
    },
    setTheme () {
      return this.getTheme(this.$route.query)
    },
    getUser () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    handleSaveCategories () {
      if (this.formData.selectedCategories.length) this.$store.dispatch('attemptUpdateUserProfile', { categories: this.formData.selectedCategories, filter: 'categories' })
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'company')) {
        window.location.href = `${this.baseUrl}/c/${this.$route.query.company}/tos?path=/c/${this.$route.query.company}&subdomain=${this.$route.query.company}${this.$route.query.help ? '&help=1' : ''}`
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'aggregator')) {
        this.$router.push({ name: 'aggregator.home.user', params: { companyDomain: this.$route.query.aggregator } })
      } else this.$router.push({ name: 'individual.home.user' })
      this.$store.dispatch('attemptMoveWizardSteps', { active: false, completed: true })
    },
    handleSelectCategory (id) {
      this.categories = this.categories.map(category => {
        if (category.id === id) return { ...category, selected: !category.selected }
        return { ...category }
      })
      const hasSelected = this.formData.selectedCategories.some(category => category === id)
      if (hasSelected) this.formData.selectedCategories = this.formData.selectedCategories.filter(category => category !== id)
      else this.formData.selectedCategories.push(id)
    },
    handleBackPage () {
      this.$emit('stepCange', { stepProgress: 2 })
      this.$router.push({ name: 'newaccount.home.firstAccess.skills', query: { ...this.$route.query } })
    }
  },
  created () {
    this.$store.dispatch('attemptGetPositionsCategory', this.getUser.language).then((data) => {
      this.categories = data.map((category) => ({ ...category, selected: false }))
      this.ready = true
    })
    this.$emit('progressChange', { stepProgress: 3 })
  }
}
</script>
<template>
  <v-card flat outlined class="categories--card mt-3">
    <v-app-bar flat class="card--header">
      <p class="text--primary">{{ $t('first.access.categories:title') }} </p>
    </v-app-bar>
    <v-card-text class="categories-selection--title">
      <h4 class="mb-1 mt-10">{{ $t('first.access.categories:title.card') }}</h4>
      <p >{{ $t('first.access.categories:subtitle.card') }}</p>
    </v-card-text>
    <loading v-if="!ready"></loading>
    <v-row v-if="ready" class="mb-6 pa-4" no-gutters>
      <v-col class="card--list pa-2" cols="12" sm="4" md="3" v-for="(category, i) in categories" :key="i">
        <v-card class="category-card rounded mx-auto" :style="{ 'border': category.selected ? `1px solid ${setTheme.primaryColor}` : ''}" flat outlined height="150" @click="handleSelectCategory(category.id)">
          <v-card-title class="header-card">
            <v-icon :style="{ 'color': category.selected ? `${setTheme.primaryColor}` : ''}"  right>{{ category.selected ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
          </v-card-title>
          <div class="body-card">
            <v-icon large class="pa-0">{{ category.icon }}</v-icon>
            <p class="mb-2 mt-2 px-1">{{ category.title }}</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions class="categories-card--footer" :class="{'no-return': isInvite}">
      <v-btn v-if="!isInvite" class="btn bold transform-unset back" text @click="handleBackPage()">{{ $t('global:back') }}</v-btn>
      <v-btn class="btn bold transform-unset next" :disabled="!formData.selectedCategories.length" :color="setTheme.primaryColor" :dark="!!formData.selectedCategories.length" @click="handleSaveCategories()">{{ $t('global:complete') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.categories--card{
  margin-bottom: 60px;
  .card--header {
    display: flex;
    justify-content: center;
    background-color: #F8F8F8;
    border-bottom: thin solid rgba(0,0,0,.12);
    p {
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      color: #212121;
      margin: 0;
    }
  }
  .categories-selection--title {
    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #212121;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #A0A0A0;
      margin-bottom: 0;
    }
  }
  .categories-subtitle {
    font-weight: 400;
    line-height: 20px;
    color: #A0A0A0;
    margin-bottom: 0;
  }
  .card--list {
    .category-card {
      border-radius: 4px;
      .header-card {
        display: flex;
        justify-content: flex-end;
        padding: 8px;
        padding-bottom: 20px;
      }
      .body-card {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          color: #212121;
        }
      }
    }
  }
  .categories-card--footer {
    display: flex;
    justify-content: space-between;
    background-color: #F8F8F8;
    border-top: thin solid rgba(0,0,0,.12);
    &.no-return {
      justify-content: flex-end;
    }
    .back {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color:#7E7D77;
    }
    .next {
      width: 123px;
      height: 36px;
      border-radius: 4px;
    }
  }
}
</style>
